import { createXApiKey } from "~/infra/http/XApiKey";

export const useXApiKey = () => {
  const xApiKey = useCookie("x-api-key", {
    default: () => createXApiKey().generateToken(),
    maxAge: 60,
  });

  return xApiKey;
};
